import "./Sidebar.scss";
import sellLogo from '../../assets/img/sell-logo.svg';
import { useSelector, useDispatch } from "react-redux";
import catService from "../../services/catService";
import { useEffect, useState } from "react";
import SidebarItem from "./components/SidebarItem/SidebarItem";
import { FiCodesandbox, FiHome } from "react-icons/fi";
import { BeatLoader } from "react-spinners";
import { AiOutlineRollback } from "react-icons/ai";
import { motion } from "framer-motion";
// import CatalogIcon from "../../icons/CatalogIcon/CatalogIcon";
import { CgTag } from "react-icons/cg";
import StatIcon from "../../icons/StatIcon/StatIcon";
import OrderIcon from "../../icons/OrderIcon/OrderIcon";
import { FiUsers, FiSettings } from "react-icons/fi";
import SettingsIcon from "../../icons/SettingsIcon/SettingsIcon";
import StatisticIcon from "../../icons/StatisticIcon/StatisticIcon";
import IntegrIcon from "../../icons/IntegrIcon/IntegrIcon";
import TrashIcon from "../../icons/TrashIcon/TrashIcon";
import { BsBag } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";
import { GoPerson } from "react-icons/go";
import { BiQuestionMark } from "react-icons/bi";
import { TbArticle } from "react-icons/tb";
import { MdOutlineDiscount } from "react-icons/md";
import { BiMessageDetail } from "react-icons/bi";
import { catalogUpdate, handleSidebarOpen } from "../../store/actions";
import { RiReservedLine } from "react-icons/ri";
import checkDomain from "../../funcs/checkDomain";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { TbTruckDelivery, TbWorldWww } from "react-icons/tb";
import { IoNewspaperOutline } from "react-icons/io5";
import { LuFlame } from "react-icons/lu";
import { MdAccessTime } from "react-icons/md";
import { MdBlock } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { IoBriefcaseSharp } from "react-icons/io5";
import { BsQuestion } from "react-icons/bs";
import { MdMenuOpen } from "react-icons/md";
import { MdOutlineLoyalty } from "react-icons/md";
import { GoProjectTemplate } from "react-icons/go";
import checkDom from "../../funcs/checkDom";
import anService from "../../services/anService";
import { MdOutlineScreenshotMonitor } from "react-icons/md";
import { checkIsTigrus } from "../../utils/checkIsTigrus";
import OrgsIcon from "../../assets/img/OrgsIcon";
import CatalogIcon from "../../assets/img/CatalogIcon";
import StoriesIcon from '../../assets/img/StoriesIcon';
import SettingIcon from '../../assets/img/SettingsIcon';
import AnalyticsIcon from '../../assets/img/AnalyticsIcon';



const cs = new catService();
const ans = new anService();

const Sidebar = () => {
  const { token, catalog, user } = useSelector((state) => state);
  const [catLoad, setCatLoad] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [totalAppOrders, setTotalAppOrders] = useState(0);
  const [totalSiteOrders, setTotalSiteOrders] = useState(0);
  const dispatch = useDispatch();
  const address = window.location.origin;

  useEffect(() => {
    setCatLoad(true);
    if (token) {
      cs.getCats(token, { OrganisationID: 0 })
        .then((res) => {
          // setCats(res)
          dispatch(catalogUpdate(res));
        })
        .finally((_) => {
          setCatLoad(false);
        });
    }
  }, [token]);

  const toggleSidebar = () => {
    setIsHide(!isHide);
  };

  useEffect(() => {
    if (isHide) {
      dispatch(handleSidebarOpen(false));
    } else {
      dispatch(handleSidebarOpen(true));
    }
  }, [isHide]);

  const getOrders = (platform) => {
    if(token) {
        // setPage(1)
        const body = {
            Field: 'id',
            Offset: 0,
            OrderBy: 'ID',
            Search: "",
            OrderType: 'DESC',
            Limit: 50, 
            Archive: 0,
            platform: platform,
        }
        ans.getOrders(token, body).then(res => {
            if(platform == 0) {
              setTotalAppOrders(Number(res?.TotalCountNewOrders))
            } else if (platform == 1) {
              setTotalSiteOrders(Number(res?.TotalCountNewOrders))
            }
             
        }).finally(_ => {
        })
        ans.getStatuses(token).then(res => {
        })
    }
  }

  useEffect(() => {
    getOrders(0);
    getOrders(1);
  }, [])

  return (
    <>
      <div className={"Sidebar-pl" + (isHide ? " hide " : "")}></div>
      <motion.div
        initial={{ translateX: "-100%" }}
        animate={{ translateX: 0 }}
        transition={{ duration: 0.5 }}
        exit={{ translateX: "-100%" }}
        className={"Sidebar gs-scroll" + (isHide ? " hide " : "")}
      >
        {/* <div className="Sidebar__head">
          <div className="Sidebar__head_label">МЕНЮ</div>

          <div className="Sidebar__head_icon" onClick={toggleSidebar}>
            <AiOutlineRollback />
          </div>
        </div> */}
        <div className="Sidebar__head">
          {/* {window.location.origin === 'https://ibazar.gscore.ru' ? null :
          <img src={sellLogo} alt="Logo" />} */}
        </div>
        {user && (
          <div className="Sidebar__list">
            {window.location.origin === 'https://mamatest.gscore.ru' || window.location.origin === 'http://localhost:3000' ? 
            <SidebarItem
            labelHide={isHide}
            name={"Заказы"}
            isSubmenu={true}
            toggleSidebar={setIsHide}
            icon={<OrderIcon size={22} />}
          >
            <SidebarItem
                    labelHide={isHide}
                    name={'Заказы с приложения'}
                    link={"/orders-app?p=Заказы с приложения"}
                    root={"/orders-app"}
                    icon={<OrderIcon size={22} />}
                    badge={totalAppOrders}
                  />
                  <SidebarItem
                    labelHide={isHide}
                    name={"Заказы с сайта"}
                    link={"/orders-site?p=Заказы с сайта"}
                    root={"/orders-site"}
                    icon={<OrderIcon size={22} />}
                    badge={totalSiteOrders}
                  />
                  <SidebarItem
                        labelHide={isHide}
                        name={"Брони"}
                        link={"/reservations?p=Брони"}
                        root={"/reservations"}
                        icon={<RiReservedLine size={22} />}
                      />
            </SidebarItem>
             : null}
            {user?.Role === "Admin" && (
              <>
                <SidebarItem
                  labelHide={isHide}
                  name={"Организации"}
                  root={"/organizations"}
                  link={`/organizations?p=Организации`}
                  icon={<OrgsIcon />}
                />
                <SidebarItem
                  labelHide={isHide}
                  toggleSidebar={setIsHide}
                  name={"Каталог"}
                  root={"/catalog"}
                  link={"/catalog?p=Каталог"}
                  isSubmenu={catalog?.length > 0 ? true : false}
                  icon={<CatalogIcon />}
                >
                  {catLoad ? (
                    <div className="SidebarItem__load">
                      <BeatLoader color="var(--violet)" />
                    </div>
                  ) : catalog && catalog.length > 0 ? (
                    catalog.map((item, index) => (
                      <SidebarItem
                        key={index}
                        labelHide={isHide}
                        icon={<FiCodesandbox />}
                        name={item.Name}
                        root={`/catalog/${item.ID}`}
                        link={`/catalog/${item.ID}?p=Каталог&p=${item.Name}`}
                      />
                    ))
                  ) : null}
                </SidebarItem>
                {window.location.origin === 'https://iiko.gscore.ru' || 
                window.location.origin === 'https://mama.gscore.ru' || 
                window.location.origin === 'https://test1.gscore.ru' || 
                window.location.origin === 'https://paulbaker.gscore.ru'|| 
                window.location.origin === 'https://darvin.gscore.ru'|| 
                window.location.origin === 'https://iiko.gscore.ru' || 
                window.location.origin === 'https://tigrus.gscore.ru' ||  
                window.location.origin === 'https://testjonny.gscore.ru' || 
                window.location.origin === 'https://uzgen.gscore.ru' || 
                window.location.origin === 'https://kekeev.gscore.ru' || 
                window.location.origin === 'https://pizzeria.gscore.ru' || 
                window.location.origin === 'https://osaka.gscore.ru' || 
                window.location.origin === 'http://localhost:3000' ?
                <>
                  <SidebarItem
                    labelHide={isHide}
                    name={'Заказы с приложения'}
                    link={"/orders-app?p=Заказы с приложения"}
                    root={"/orders-app"}
                    icon={<OrderIcon size={22} />}
                    badge={totalAppOrders}
                  />
                  <SidebarItem
                    labelHide={isHide}
                    name={"Заказы с сайта"}
                    link={"/orders-site?p=Заказы с сайта"}
                    root={"/orders-site"}
                    icon={<OrderIcon size={22} />}
                    badge={totalSiteOrders}
                  />
                </> : null
              }
                 {window.location.origin === 'http://localhost:3000' ||
                  window.location.origin === 'https://mama.gscore.ru' || 
                  window.location.origin === 'https://test1.gscore.ru' || 
                  window.location.origin === 'https://paulbaker.gscore.ru'|| 
                  window.location.origin === 'https://darvin.gscore.ru'|| 
                  window.location.origin === 'https://pizzeria.gscore.ru'|| 
                  window.location.origin === 'https://kekeev.gscore.ru'|| 
                  window.location.origin === 'https://uzgen.gscore.ru'|| 
                  window.location.origin === 'https://iiko.gscore.ru' ?
                 <> <SidebarItem
                  labelHide={isHide}
                  name={"Хиты категорий"}
                  link={"/categories-hits?p=Хиты категорий"}
                  root={"/categories-hits"}
                  icon={<LuFlame size={22} />}
                />
                 <SidebarItem
                  labelHide={isHide}
                  name={"Хиты блюд"}
                  link={"/plates-hits?p=Хиты блюд"}
                  root={"/plates-hits"}
                  icon={<LuFlame size={22} />}
                />
                </> : null}
                <SidebarItem
                  labelHide={isHide}
                  name={"Сториз"}
                  link={
                    window.location.origin?.includes("mamatest.gscore.ru") || 
                    window.location.origin?.includes("uzgen.gscore.ru") ||
                     window.location.origin?.includes("mama.gscore.ru") || 
                     window.location.origin?.includes("test1.gscore.ru") || 
                     window.location.origin?.includes('pizzeria.gscore.ru')
                      ? "/stories-ym?p=Сториз" 
                      : "/stories?p=Сториз"
                  }
                  root={
                    window.location.origin?.includes("mamatest.gscore.ru") || 
                    window.location.origin?.includes("uzgen.gscore.ru") || 
                    window.location.origin?.includes("mama.gscore.ru") || 
                    window.location.origin?.includes("test1.gscore.ru") || 
                    window.location.origin?.includes('pizzeria.gscore.ru')
                      ? "/stories-ym"
                      : "/stories"
                  }
                  // icon={<CgTag style={{ transform: "rotate(-45deg)" }} />}
                  icon={<StoriesIcon></StoriesIcon>}
                />
              </>
            )}
            <SidebarItem
              labelHide={isHide}
              name={"Аналитика"}
              isSubmenu={true}
              toggleSidebar={setIsHide}
              // icon={<StatIcon size={22} />}
              icon={<AnalyticsIcon></AnalyticsIcon>}
            >
              {user?.Role === "Admin" && (
                <>
                 <SidebarItem
                    labelHide={isHide}
                    name={"Статистика"}
                    link={"/statistic?p=Статистика"}
                    root={"/statistic"}
                    icon={<StatisticIcon size={22} />}
                  />
                  {checkDomain(
                    <>
                    {window.location.origin === 'https://ibazar.gscore.ru' || window.location.origin === 'https://ident.gscore.ru' ? null : <SidebarItem
                        labelHide={isHide}
                        name={"Брони"}
                        link={"/reservations?p=Брони"}
                        root={"/reservations"}
                        icon={<RiReservedLine size={22} />}
                      />}
                    </>,
                    null
                  )}
                 
                </>
              )}
              <SidebarItem
                labelHide={isHide}
                name={"Клиенты"}
                link={"/clients?p=Клиенты"}
                root={"/clients"}
                icon={<FiUsers />}
              />
              {/*<SidebarItem*/}
              {/*    labelHide={isHide}*/}
              {/*    name={'Заказы с мобильных устройств'}*/}
              {/*    link={'/orders?p=Заказы'}*/}
              {/*    root={'/orders'}*/}
              {/*    icon={<OrderIcon size={22}/>}/>*/}
              {
              address === "https://mama.gscore.ru" || 
              address === "https://test1.gscore.ru" || 
              address === 'https://iiko.gscore.ru' ? 
                null
               : (<SidebarItem
              labelHide={isHide}
              name={"Заказы"}
              link={"/orders?p=Заказы"}
              root={"/orders"}
              icon={<OrderIcon size={22} />}
            />)}

              
              <SidebarItem
                  labelHide={isHide}
                  name={"Оценки"}
                  link={"/revs?p=Оценки"}
                  root={"/revs"}
                  icon={<AiOutlineStar size={22} />}
                />
              {window.location.origin === 'https://iiko.gscore.ru' || 
              window.location.origin === 'https://mama.gscore.ru' || 
              window.location.origin === 'https://test1.gscore.ru' || 
              window.location.origin === 'https://paulbaker.gscore.ru'||
              window.location.origin === 'https://darvin.gscore.ru'||
               window.location.origin === 'https://iiko.gscore.ru' || 
              window.location.origin === 'https://tigrus.gscore.ru' || 
              window.location.origin === 'https://testjonny.gscore.ru' || 
              window.location.origin === 'https://uzgen.gscore.ru' || 
              window.location.origin === 'https://kekeev.gscore.ru' || 
              window.location.origin === 'https://pizzeria.gscore.ru' || 
              window.location.origin === 'https://osaka.gscore.ru' || 
              window.location.origin === 'http://localhost:3000' ?
                  <SidebarItem
                    labelHide={isHide}
                    name={"Отзывы с сайта"}
                    link={"/revs-site?p=Отзывы с сайта"}
                    root={"/revs-site"}
                    icon={<AiOutlineStar size={22} />}
                  />: null
              }
              
            </SidebarItem>
            {process.env.REACT_APP_ENABLE_SITE_CONFIGURATION === "true" && (
              <SidebarItem
                labelHide={isHide}
                name={"Настройки сайта"}
                toggleSidebar={setIsHide}
                isSubmenu={true}
                icon={<TbWorldWww size={22} />}
              >
                <SidebarItem
                  root={"/site/status"}
                  labelHide={isHide}
                  name={"Статус сайта"}
                  link={"/site/status"}
                  icon={<HiOutlineStatusOnline />}
                />
                <SidebarItem
                  root={"/site/reservation"}
                  labelHide={isHide}
                  name={"Бронь"}
                  link={"/site/reservation"}
                  icon={<MdAccessTime />}
                />
                <SidebarItem
                  root={"/site/delivery"}
                  labelHide={isHide}
                  name={"Информация о доставке"}
                  link={"/site/delivery"}
                  icon={<TbTruckDelivery />}
                />
                <SidebarItem
                  root={"/site/news"}
                  labelHide={isHide}
                  name={"Новости и мероприятия"}
                  link={"/site/news"}
                  icon={<IoNewspaperOutline />}
                />
              </SidebarItem>
            )}
            {user?.Role === "Admin" && (
              <SidebarItem
                labelHide={isHide}
                name={"Настройки"}
                toggleSidebar={setIsHide}
                isSubmenu={true}
                // icon={<SettingsIcon size={22} />}
                icon={<SettingIcon></SettingIcon>}
              >
                <SidebarItem
                  root={"/basket"}
                  labelHide={isHide}
                  name={"Корзина"}
                  link={"/basket?p=Настройки корзины"}
                  icon={<BsBag />}
                />
                {window.location.origin === 'https://ibazar.gscore.ru' || window.location.origin === 'https://ident.gscore.ru' ? null :  <SidebarItem
                  labelHide={isHide}
                  root={"/integr"}
                  name={"Интеграции"}
                  link={"/integr?p=Интеграции"}
                  icon={<IntegrIcon size={22} />}
                />}
               
                <SidebarItem
                  labelHide={isHide}
                  name={"Все настройки"}
                  link={"/allsettings?p=Все настройки"}
                  root={"/allsettings"}
                  icon={<FiSettings />}
                />
                <SidebarItem
                  labelHide={isHide}
                  name={"Настройки отзывов"}
                  link={"/ratingSettings?p=Настройки отзывов"}
                  root={"/ratingSettings"}
                  icon={<BiMessageDetail size={22} />}
                />
                {window.location.origin === 'https://pizzburg.gscore.ru' || window.location.origin === 'http://localhost:3000' ?
                <SidebarItem
                  labelHide={isHide}
                  name={"Уровни лояльности"}
                  link={"/loyalty?p=Уровни лояльности"}
                  root={"/loyalty"}
                  icon={<MdOutlineLoyalty size={22} />}
                /> : null}
                {window.location.origin === 'https://mamatest.gscore.ru' || window.location.origin === 'http://localhost:3000' ?
                <SidebarItem
                  labelHide={isHide}
                  name={"Пользователи"}
                  link={"/admin-users?p=Пользователи"}
                  root={"/admin-users"}
                  icon={<FiSettings size={22} />}
                /> : null}
                <SidebarItem
                  labelHide={isHide}
                  name={"Удаленные объекты"}
                  link={"/trash?p=Удаленные обьекты"}
                  root={"/trash"}
                  icon={<TrashIcon size={22} />}
                />
                {window.location.origin === 'https://mamatest.gscore.ru' ? <>
                <SidebarItem
                  labelHide={isHide}
                  name={"Вакансии"}
                  link={"/vacancies?p=Вакансии"}
                  root={"/vacansies"}
                  icon={<GoPerson size={22} />}
                />
                {/* <SidebarItem
                  labelHide={isHide}
                  name={"Вопрос-ответ"}
                  link={"/q-a?p=Вопрос-ответ"}
                  root={"/q-a"}
                  icon={<BiQuestionMark size={22} />}
                /> */}
                <SidebarItem
                  labelHide={isHide}
                  name={"Акции"}
                  link={"/articles?p=Акции"}
                  root={"/articles"}
                  icon={<TbArticle size={22} />}
                />
                {/* <SidebarItem
                  labelHide={isHide}
                  name={"Акции"}
                  link={"/promotions?p=Акции"}
                  root={"/promotions"}
                  icon={<MdOutlineDiscount size={22} />}
                /> */}
                <SidebarItem
                  labelHide={isHide}
                  name={"Доставка и оплата"}
                  link={"/pay-delivery?p=Доставка и оплата"}
                  root={"/pay-delivery"}
                  icon={<TbTruckDelivery size={22} />}
                />
                <SidebarItem
                  labelHide={isHide}
                  name={"Бонусы и программы"}
                  link={"/bonuses-program?p=Бонусы и программы"}
                  root={"/bonuses-program"}
                  icon={<FaPlus size={22} />}
                />
                <SidebarItem
                  labelHide={isHide}
                  name={"Сотрудничество"}
                  link={"/partnership?p=Сотрудничество"}
                  root={"/partnership"}
                  icon={<IoBriefcaseSharp size={22} />}
                />
                <SidebarItem
                  labelHide={isHide}
                  name={"Новости"}
                  link={"/news?p=Новости"}
                  root={"/news"}
                  icon={<IoNewspaperOutline size={22} />}
                />
                                <SidebarItem
                  labelHide={isHide}
                  name={"Навигация"}
                  link={"/navigation?p=Навигация"}
                  root={"/navigation"}
                  icon={<MdMenuOpen size={22} />}
                />
                <SidebarItem
                  labelHide={isHide}
                  name={"Правила доставки"}
                  link={"/delivery-rules?p=Правила доставки"}
                  root={"/delivery-rules"}
                  icon={<BsQuestion size={22} />}
                />
                <SidebarItem
                  labelHide={isHide}
                  name={"Блокировка"}
                  link={"/accessibility?p=Блокировка"}
                  root={"/accessibility"}
                  icon={<MdBlock size={22} />}
                />
            
              </> : null}
              </SidebarItem>
            )}
            {window.location.origin === 'https://mamatest.gscore.ru' || window.location.origin === 'http://localhost:3000' ?
              <SidebarItem
                labelHide={isHide}
                name={"Промо"}
                toggleSidebar={setIsHide}
                isSubmenu={true}
                icon={<GoProjectTemplate size={22} />}
              >
              <SidebarItem
                  labelHide={isHide}
                  name={"Хиты категорий"}
                  link={"/categories-hits?p=Хиты категорий"}
                  root={"/categories-hits"}
                  icon={<LuFlame size={22} />}
                />
                 <SidebarItem
                  labelHide={isHide}
                  name={"Хиты блюд"}
                  link={"/plates-hits?p=Хиты блюд"}
                  root={"/plates-hits"}
                  icon={<LuFlame size={22} />}
                />
                <SidebarItem
                  labelHide={isHide}
                  name={"Сториз"}
                  link={
                    window.location.origin?.includes("mamatest.gscore.ru") || window.location.origin?.includes("uzgen.gscore.ru")
                      ? "/stories-ym?p=Сториз"
                      : "/stories?p=Сториз"
                  }
                  root={
                    window.location.origin?.includes("mamatest.gscore.ru") || window.location.origin?.includes("uzgen.gscore.ru")
                      ? "/stories-ym"
                      : "/stories"
                  }
                  icon={<CgTag style={{ transform: "rotate(-45deg)" }} />}
                />
                <SidebarItem
                  labelHide={isHide}
                  name={"Баннеры"}
                  link={"/banners?p=Баннеры"}
                  root={"/banners"}
                  icon={<MdOutlineScreenshotMonitor size={22} />}
                />
              </SidebarItem>
            : null}
{checkIsTigrus() && (
                  <SidebarItem
                  labelHide={isHide}
                  name={"Баннеры"}
                  link={"/banners?p=Баннеры"}
                  root={"/banners"}
                  icon={<MdOutlineScreenshotMonitor size={22} />}
                />
                )}
                {(window.location.host.includes('uzgen.gscore.ru') || 
                window.location.host.includes('mama.gscore.ru') || 
                window.location.host.includes('test1.gscore.ru') || 
                window.location.host.includes('paulbaker.gscore.ru') || 
                window.location.host.includes('darvin.gscore.ru') || 
                window.location.host.includes('iiko.gscore.ru') || 
                window.location.host.includes('ibazar.gscore.ru') ||
                window.location.host.includes('ident.gscore.ru') || 
                window.location.host.includes('macarons.gscore.ru')) &&  
                  <SidebarItem
                    labelHide={isHide}
                    name={"Баннеры"}
                    link={"/banners?p=Баннеры"}
                    root={"/banners"}
                    icon={<MdOutlineScreenshotMonitor size={22} />}
                  />
                }
            {window.location.origin === 'https://iiko.gscore.ru' || 
            window.location.origin === 'https://mama.gscore.ru' || 
            window.location.origin === 'https://test1.gscore.ru' || 
            window.location.origin === 'https://paulbaker.gscore.ru'|| 
            window.location.origin === 'https://darvin.gscore.ru'|| 
            window.location.origin === 'https://iiko.gscore.ru' || 
            window.location.origin === 'https://tigrus.gscore.ru' || 
            window.location.origin === 'https://testjonny.gscore.ru' || 
            window.location.origin === 'https://uzgen.gscore.ru' || 
            window.location.origin === 'https://kekeev.gscore.ru' || 
            window.location.origin === 'https://pizzeria.gscore.ru' || 
            window.location.origin === 'https://osaka.gscore.ru' || 
            window.location.origin === 'http://localhost:3000' ?
              <>
                <SidebarItem
                  labelHide={isHide}
                  name={"Вакансии"}
                  link={"/vacancies?p=Вакансии"}
                  root={"/vacansies"}
                  icon={<GoPerson size={22} />}
                />
                {/* <SidebarItem
                  labelHide={isHide}
                  name={"Вопрос-ответ"}
                  link={"/q-a?p=Вопрос-ответ"}
                  root={"/q-a"}
                  icon={<BiQuestionMark size={22} />}
                /> */}
                <SidebarItem
                  labelHide={isHide}
                  name={"Акции"}
                  link={"/articles?p=Акции"}
                  root={"/articles"}
                  icon={<TbArticle size={22} />}
                />
                {/* <SidebarItem
                  labelHide={isHide}
                  name={"Акции"}
                  link={"/promotions?p=Акции"}
                  root={"/promotions"}
                  icon={<MdOutlineDiscount size={22} />}
                /> */}
                <SidebarItem
                  labelHide={isHide}
                  name={"Доставка и оплата"}
                  link={"/pay-delivery?p=Доставка и оплата"}
                  root={"/pay-delivery"}
                  icon={<TbTruckDelivery size={22} />}
                />
                <SidebarItem
                  labelHide={isHide}
                  name={"Бонусы и программы"}
                  link={"/bonuses-program?p=Бонусы и программы"}
                  root={"/bonuses-program"}
                  icon={<FaPlus size={22} />}
                />
                <SidebarItem
                  labelHide={isHide}
                  name={"Сотрудничество"}
                  link={"/partnership?p=Сотрудничество"}
                  root={"/partnership"}
                  icon={<IoBriefcaseSharp size={22} />}
                />

              </> : null
            }
            {window.location.origin === 'https://mama.gscore.ru' || 
            window.location.origin === 'https://test1.gscore.ru' || 
            window.location.origin === 'https://paulbaker.gscore.ru'|| 
            window.location.origin === 'https://darvin.gscore.ru'|| 
            window.location.origin === 'https://iiko.gscore.ru' || 
            window.location.origin === 'https://tigrus.gscore.ru' || 
            window.location.origin === 'https://testjonny.gscore.ru' || 
            window.location.origin === 'https://uzgen.gscore.ru' || 
            window.location.origin === 'https://kekeev.gscore.ru' || 
            window.location.origin === 'https://pizzeria.gscore.ru' || 
            window.location.origin === 'https://osaka.gscore.ru' || 
            window.location.origin === 'http://localhost:3000' ? 
            <>
                <SidebarItem
                  labelHide={isHide}
                  name={"Новости"}
                  link={"/news?p=Новости"}
                  root={"/news"}
                  icon={<IoNewspaperOutline size={22} />}
                />
                                <SidebarItem
                  labelHide={isHide}
                  name={"Навигация"}
                  link={"/navigation?p=Навигация"}
                  root={"/navigation"}
                  icon={<MdMenuOpen size={22} />}
                />
                <SidebarItem
                  labelHide={isHide}
                  name={"Правила доставки"}
                  link={"/delivery-rules?p=Правила доставки"}
                  root={"/delivery-rules"}
                  icon={<BsQuestion size={22} />}
                />
                <SidebarItem
                  labelHide={isHide}
                  name={"Блокировка"}
                  link={"/accessibility?p=Блокировка"}
                  root={"/accessibility"}
                  icon={<MdBlock size={22} />}
                />
            </>
            : null}
            {window.location.origin === 'https://mamatest.gscore.ru' || window.location.origin === 'http://localhost:3000' ? <SidebarItem
                labelHide={isHide}
                name={"Лояльность"}
                toggleSidebar={setIsHide}
                isSubmenu={true}
                icon={<GoProjectTemplate size={22} />}
              >
              <SidebarItem
                  labelHide={isHide}
                  name={"Накопление баллов"}
                  link={"/?p=Пока что нет страницы"}
                  root={"/"}
                  icon={<CgTag size={22} />}
                />
              <SidebarItem
                  labelHide={isHide}
                  name={"Подарки от сумм покупки"}
                  link={"/?p=Пока что нет страницы"}
                  root={"/"}
                  icon={<CgTag size={22} />}
                />
              <SidebarItem
                  labelHide={isHide}
                  name={"Подарки к блюдам"}
                  link={"/?p=Пока что нет страницы"}
                  root={"/"}
                  icon={<CgTag size={22} />}
                />
              <SidebarItem
                  labelHide={isHide}
                  name={"Промокоды"}
                  link={"/?p=Пока что нет страницы"}
                  root={"/"}
                  icon={<CgTag size={22} />}
                />
              <SidebarItem
                  labelHide={isHide}
                  name={"Уровни лояльности"}
                  link={"/?p=Пока что нет страницы"}
                  root={"/"}
                  icon={<CgTag size={22} />}
                />
              <SidebarItem
                  labelHide={isHide}
                  name={"5ый кофе в подарок"}
                  link={"/?p=Пока что нет страницы"}
                  root={"/"}
                  icon={<CgTag size={22} />}
                />
              <SidebarItem
                  labelHide={isHide}
                  name={"Счастливые часы"}
                  link={"/?p=Пока что нет страницы"}
                  root={"/"}
                  icon={<CgTag size={22} />}
                />
                </SidebarItem> : null}
          </div>
        )}
      </motion.div>
    </>
  );
};

export default Sidebar;
